import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {

    return(
        <>
        
            <header id="header" className="header">
                    
                <div className="">
                
                    <div className="navigation bg-brandcox-firefly">
                        <div className="container-fluid">
                            <nav className="main-nav navbar navbar-right navbar-expand-md">
                                <Link to="/" className="navbar-brand logo" href=""><img src="/assets/img/checkoff-credit_logo_stripped_small.png" alt="" /></Link>
                        
                                <button className="navbar-toggler collapsed" type="button" data-toggle="collapse" data-target="#navbar-collapse">
                            <span className="menu_toggle">
                            <span className="hamburger">
                                <span />
                                <span />
                                <span />
                            </span>
                            <span className="hamburger-cross">
                                <span />
                                <span />
                            </span>
                            </span>
                        </button>
                        
                                <div id="navbar-collapse" className="navbar-collapse collapse">
                            {/* left */}
                            <ul className="nav navbar-nav font-circular pdl">

                                <li className="nav-item"><Link className="nav-link" to="/about"><span className="fs-14">About Us</span></Link></li>
                                <li className="nav-item"><Link className="nav-link" to="/contact"><span className="fs-14">Contact Us</span></Link></li>
                               
                            </ul>
                            {/* right */}
                            <ul className="nav navbar-nav ml-auto font-circular">
                                <li className="nav-item hd--tab">
                                    <a href="tel:+2349082600487" className="nav-link dropdown-toggle ui-hide-mobile-only" >
                                        <span className="icon-img"><img src="images/assets/phone.svg"  alt="icon"/></span>
                                    </a>
                                </li>

                                <li className="nav-item">
                                    <Link className="nav-link btn-apply font-gilroybold" to="/apply">
                                        Apply Now
                                    </Link>
                                </li>
                                
                            </ul>
                        </div>
                            </nav>
                        </div>
                    </div>
                
                </div>
                
            </header>
   
        </>
    )

}

export default NavBar;