export const camelCase = str => str
  .toLowerCase()
  .split(' ')
  .map((word) => {
    if (word[0]) {
      return word[0].toUpperCase() + word.substr(1);
    }
  })
  .join(' ');

export const allUpper = str => str
  .toUpperCase();
