import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Jumbotron,
  Button,
  Form,
  ButtonGroup,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
  Alert,
} from "reactstrap";
import axios from "axios";
import numeral from "numeral";
import FontAwesome from "react-fontawesome";
import { Animated } from "react-animated-css";
import moment from "moment";
import FormField from "./widgets/FormFields";
import {
  getRepayment,
  getmaxloan,
  getnewrepayment,
} from "./widgets/loanCalculator";
import { camelCase, allUpper } from "./widgets/string";
import FileBase64 from "./widgets/inputbase64";

class NewLoann extends Component {
  state = {
    submittedDone: false,
    currentStep: 2,
    registerError: "",
    loading: false,
    recruiters: [],
    recruiter: {},
    customerId: "",
    terms: false,
    formdata: {
      loanTenor: {
        element: "select",
        value: "",
        config: {
          name: "lt_input",
          type: "text",
          disabled: false,
          label: "Loan Tenure",
          options: [
            {
              name: "",
              id: "",
            },
            {
              name: "3 Months",
              id: "3",
            },
            {
              name: "6 Months",
              id: "6",
            },
            {
              name: "9 Months",
              id: "9",
            },
            {
              name: "12 Months",
              id: "12",
            },
          ],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },

      amountDisbursable: {
        element: "input",
        value: "",
        config: {
          name: "la_input",
          type: "number",
          disabled: true,
          label: "Amount to be Disbursed",
          placeholder: "xxxxxx",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      loanAmt: {
        element: "input",
        value: "",
        config: {
          name: "la_input",
          type: "text",
          label: "Loan Amount",
          placeholder: "Enter Loan Amount",
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      loanRepayment: {
        element: "input",
        value: "",
        config: {
          name: "lp_input",
          type: "text",
          label: "Loan Repayment",
          disabled: true,
        },
        validation: { required: true },
        valid: true,
      },
      repaymentDays: {
        element: "input",
        value: "",
        config: {
          name: "frd_input",
          type: "text",
          label: "First Repayment Date",
          disabled: true,
        },
        validation: { required: true },
        valid: true,
      },
      processingFee: {
        element: "input",
        value: "",
        config: {
          name: "pf_input",
          type: "number",
          label: "Processing Fee",
          disabled: true,
        },
        validation: { required: false },
        valid: true,
      },
      dse: {
        element: "select",
        value: "",
        config: {
          name: "dse_input",
          type: "text",
          label: "Direct Sales Executive",
          options: [],
        },
        validation: {
          required: true,
        },
        valid: true,
        touched: false,
        validationMessage: "",
      },
      maximumloanAmt: {
        element: "input",
        value: "",
        config: {
          name: "maximumloanAmt_input",
          type: "text",
          label: "Maximum Loan Amount",
          disabled: true,
        },
        validation: {},
        valid: true,
      },
      // ippis: {
      //   element: 'input',
      //   value: '',
      //   config: {
      //     name: 'ippis_input',
      //     type: 'text',
      //     label: 'Employee/IPPIS Number',
      //     disabled: false,
      //   },
      //   validation: { required: true },
      //   valid: true,
      // },
    },
  };

  componentDidMount() {
    console.log(this.props.client);
    const formdataCopy = {
      ...this.state.formdata,
    };

    // 33 for bank

    axios
      .get("/api/getRecruiters")
      .then((resp) => {
        //  console.log(resp);
        // find recruiter from array
        const recruiter = resp.data.filter(
          (rec) => String(rec._id) === this.props.client.recruiter
        )[0];
        const dseOptions = [
          {
            name: "",
            id: "",
          },
        ];
        if (recruiter) {
          this.setState({ recruiter });
          formdataCopy.maximumloanAmt.value = recruiter.maximum;
          formdataCopy.processingFee.value = recruiter.fee;
          formdataCopy.repaymentDays.value = moment(
            recruiter.repaymentDays,
            "DD"
          )
            .add(1, "M")
            .format("DD MMMM YYYY");
          const Tenoroptions = [
            {
              name: "",
              id: "",
            },
          ];

          for (let i = 3; i <= parseInt(recruiter.returningTenor, 10); i += 3) {
            if (i == 1) {
              Tenoroptions.push({
                name: `${i} Month`,
                id: i,
              });
            } else {
              Tenoroptions.push({
                name: `${i} Months`,
                id: i,
              });
            }

            // console.log(i);
            // if (i >= parseInt(name[5], 10)) {
            //   break;
            // }
          }
          formdataCopy.loanTenor.config.options = Tenoroptions;
          //  console.log(recruiter.maximum);
        }

        // console.log(recruiter);

        // formdataCopy.ban.config.options = bankOptions;
        axios
          .get("/api/getDSE")
          .then((dseresp) => {
            //  console.log(resp);
            let dsename;

            // console.log(resp.data);
            dseresp.data.map((dse) => {
              // console.log(recruiter);
              dsename = dse.name;

              dseOptions.push({
                name: dsename,
                id: dsename,
              });
            });
            // console.log(recruiterOptions);
            formdataCopy.dse.config.options = dseOptions;
            this.setState({
              formdata: formdataCopy,
              recruiters: resp.data,
              recruiter,
            });
          })
          .catch((err) => {
            console.error(err.response);
          });
      })
      .catch((err) => {
        console.error(err.response);
      });
  }

  onRadioBtnClick(gender) {
    this.setState({
      gender,
    });
  }

  // handleImage = (e) => {
  //   const file = e.target.files[0];
  //   // console.log(file);
  //   if (file.size > '600000') {
  //     return alert('File is too large, file size limit is 600kb');
  //   }
  //   this.setState({
  //     file,
  //   });
  // };

  termsChange = () => {
    this.setState((prevState) => ({
      terms: !prevState.terms,
    }));
  };

  validate = (element) => {
    let error = [true, ""];

    if (element.validation.email) {
      const valid = /\S+@\S+\.\S+/.test(element.value);
      const message = `${!valid ? "Must be a valid email" : ""}`;
      error = !valid ? [valid, message] : error;
    }

    if (element.validation.words3) {
      const valid = element.value.trim().split(" ").length >= 2;
      const message = `${!valid ? "Please input your full name" : ""}`;
      error = !valid ? [valid, message] : error;
    }
    if (element.validation.required) {
      const valid = element.value.trim() !== "";
      const message = `${!valid ? "This field is required" : ""}`;
      error = !valid ? [valid, message] : error;
    }
    return error;
  };

  updateForm = (element) => {
    let inputData = "";
    if (element.event.target.type == "text") {
      inputData = allUpper(element.event.target.value);
    } else {
      inputData = element.event.target.value;
    }
    const newFormdata = {
      ...this.state.formdata,
    };
    const newElement = {
      ...newFormdata[element.id],
    };

    if (element.id === "loanAmt") {
      newFormdata.loanRepayment.value = 0;
      newFormdata.amountDisbursable.value = 0;
    }
    // add processing fee on submit

    if (inputData && element.id === "loanAmt") {
      // console.log(this.state.formdata.loanTenor.value);
      // console.log(this.state.formdata.maximumloanAmt.value);
      const name = 1000000;
      if (this.state.recruiter.value) {
        //  name = this.state.formdata.recruiter.value.split(',');
      }
      if (
        inputData >
        parseInt(
          numeral(`${this.state.formdata.maximumloanAmt.value}`).value(),
          10
        )
      ) {
        const maxreason = this.state.formdata.maximumloanAmt.value;
        return this.setState({
          loading: false,
          registerError: `You can only apply for the maximum of ${maxreason} for ${this.state.formdata.loanTenor.value} MONTHS`,
        });
      }
      //  const recruiterData = this.state.formdata.recruiter.value.split(',');
      if (this.state.formdata.loanTenor.value) {
        newFormdata.loanRepayment.value = numeral(
          getnewrepayment(
            inputData,
            this.state.recruiter.returningInterest,
            this.state.formdata.loanTenor.value,
            false
          )
        ).format("0,0.00");
      }
    }
    if (element.id === "loanAmt" && this.state.recruiter && inputData) {
      const loanAmount = inputData * 1;
      //  inputData *= 1;
      // console.log(loanAmount > this.state.recruiter.fee * 1);
      if (loanAmount > this.state.recruiter.fee * 1) {
        if (this.state.recruiter.feeType.toLowerCase().trim() === "flat") {
          newFormdata.amountDisbursable.value =
            loanAmount * 1 - this.state.recruiter.fee * 1;
        } else if (
          this.state.recruiter.feeType.toLowerCase().trim() === "percentage"
        ) {
          newFormdata.amountDisbursable.value =
            loanAmount - (this.state.recruiter.fee / 100) * loanAmount;
        }
      }
      //  newFormdata.amountDisbursable.value = this.state.recruiter.feeType;
      // console.log(this.state.recruiter.fee);
      // console.log(inputData);
      // console.log(inputData = inputData * 1 + this.state.recruiter.fee * 1);
    }

    let maxloan = 0;
    let maxreason = 0;
    let loanten = this.state.formdata.loanTenor.value;
    // let recruiterData = this.state.formdata.recruiter.value.split(',');
    let sal = this.props.client.monthlySalary;

    if (element.id === "loanTenor") {
      loanten = inputData;
    } else if (element.id === "recruiter") {
      // recruiterData = inputData.split(',');
    } else if (element.id === "monthlySalary") {
      sal = inputData;
    }
    //  console.log(loanten, sal, ' loan ten and salary');
    if (loanten && sal) {
      maxloan = getmaxloan(
        getRepayment(sal, this.state.recruiter.returningDti, 0),
        loanten,
        this.state.recruiter.returningInterest
      );
      maxreason =
        this.state.recruiter.maximum < maxloan
          ? this.state.recruiter.maximum
          : maxloan;
    }
    // console.log(maxreason, 'maxreason');
    if (maxreason) {
      newFormdata.maximumloanAmt.value = Math.round(maxreason / 10000) * 10000;
    }

    if (element.id === "loanTenor") {
      if (this.state.formdata.loanAmt.value) {
        if (
          this.state.formdata.loanAmt.value >
          parseInt(
            numeral(`${this.state.formdata.maximumloanAmt.value}`).value(),
            10
          )
        ) {
          return this.setState({
            loading: false,
            registerError: `You can only apply for the maximum of ${this.state.formdata.maximumloanAmt.value} for ${this.state.formdata.loanTenor.value} MONTHS`,
          });
        }
        if (this.state.formdata.loanAmt.value) {
          newFormdata.loanRepayment.value = numeral(
            getnewrepayment(
              this.state.formdata.loanAmt.value,
              this.state.recruiter.returningInterest,
              inputData,
              false
            )
          ).format("0,0.00");
        }
      }
    }
    newElement.value = inputData;
    if (element.blur) {
      //  console.log(element.id);
      const validData = this.validate(newElement);
      newElement.valid = validData[0];
      newElement.validationMessage = validData[1];
    }
    newElement.touched = element.blur;
    newFormdata[element.id] = newElement;

    this.setState({
      formdata: newFormdata,
    });
  };

  submitForm = (event) => {
    event.preventDefault();
    if (this.state.submittedDone) return;
    if (!this.state.terms) {
      return this.setState({
        registerError: "You have to accept the Terms and Condition",
      });
    }

    const dataToSubmit = {};
    let formIsValid = true;

    for (const key in this.state.formdata) {
      dataToSubmit[key] = this.state.formdata[key].value;
    }

    const repayment = numeral(`${dataToSubmit.loanRepayment}`).value() * 1;
    const loanAmount = numeral(`${dataToSubmit.loanAmt}`).value() * 1;
    // if (loanAmount && !repayment) {
    //   return this.calculateSche(dataToSubmit.phoneNumber, loanAmount, dataToSubmit.loanTenor, recruiterData[0].toLowerCase() != 'not applicable' ? null : (recruiterData[0].toLowerCase() == 'not applicable' && dataToSubmit.loanTenor == 1) ? 4 : 5);
    // }
    if (repayment < 1) {
      return this.setState({
        registerError: "Loan repayment is empty",
      });
    }

    for (const key in this.state.formdata) {
      if (
        this.state.formdata[key].validation.required &&
        !this.state.formdata[key].value
      ) {
        // console.log(key);
        formIsValid = false;
        break;
      }
      // formIsValid = this.state.formdata[key].valid && this.state.formdata[key].validation.required && formIsValid;
      // console.log(key, formIsValid);
    }

    if (loanAmount < this.state.recruiter.minimum) {
      return this.setState({
        loading: false,
        registerError: `You can only apply for a minimum of ${this.state.recruiter.minimum}`,
      });
    }
    if (!formIsValid) {
      return this.setState({
        loading: false,
        registerError: "Please Fill all Required Fields",
      });
    }

    this.setState({
      loading: true,
    });
    const daydiff = moment(dataToSubmit.nextSalaryDate).diff(moment(), "days");

    const load = {
      ...dataToSubmit,
      // interest: recruiterData[0].toLowerCase() != 'not applicable' ? recruiterData[2] : (recruiterData[0].toLowerCase() == 'not applicable' && dataToSubmit.loanTenor == 1) ? 0.004 * (daydiff < 15 ? 15 : daydiff) : 0.05,
      recruiter: this.state.recruiter._id,
      loanAmount,
      repayment,
      phoneNumber: this.props.client.phoneNumber,
      type: "dontcreateonmifos",
      isTopup: true,
      // genderType: this.state.gender == 31 ? 'Male' : 'Female',
    };

    console.log(load);
    axios
      .post("/api/createLoan", load)
      .then((response) => {
        const data = response.data;
        if (data && data.err) {
          this.setState({
            loading: false,
            registerError: data.err,
          });
          return;
        }

        this.setState({
          loading: false,
          registerError:
            "Loan Submitted Successfully for Processing. Changes will Reflect on your Dashboard within 24 Hours. You will be contacted soon",
          submittedDone: true,
        });
        // if (
        //   data
        //         && dataToSubmit.repaymentOption == 'direct'
        //         && data.mandate
        //         && data.mandate.startDate
        // ) {
        //   // if (data.online) {
        //   //   axios
        //   //     .post('/api/requestOtp', {
        //   //       mandateId: data.mandate.mandateId,
        //   //       requestId: data.mandate.requestId,
        //   //     })
        //   //     .then(({ data }) => {
        //   //       this.props.history.push(
        //   //         `/activateMandate/${btoa(`${JSON.stringify(data)}`)}`,
        //   //       );
        //   //     })
        //   //     .catch(({ response }) => {
        //   //       console.log(response);
        //   //     });
        //   // } else {
        //   setTimeout(() => {
        //     this.props.history.push(
        //       `/mandate/${btoa(
        //         `${data.mandate.mandateId}&${data.payerName}&${data.payerPhone}&${data.payerEmail}&${data.bank}&${data.account}&${data.amount}&${data.mandate.startDate}&${data.mandate.endDate}`,
        //       )}`,
        //     );
        //   }, 3000);
        //   // }
        // } else {
        //   setTimeout(() => {
        //     this.props.history.push('/login');
        //   }, 3000);
        // }
        // console.log(d_resp);
      })
      .catch((err) => {
        console.error(err.response);

        if (err.response.data.error) {
          this.setState({
            loading: false,
            registerError: err.response.data.error.defaultUserMessage,
          });
          // setTimeout(() => {
          //   this.props.history.push('/login');
          // }, 3000);
          return;
        }
        // if (err.response.data.message && err.response.data.message.split(':')) {
        //   const errr = err.response.data.message.split(':');
        //   this.setState({
        //     loading: false,
        //     registerError: errr[2],
        //   });
        //   // setTimeout(() => {
        //   //   this.props.history.push('/login');
        //   // }, 3000);
        //   return;
        // }
        this.setState({
          loading: false,
          registerError: "An Error Occured",
        });
      });

    // }
  };

  render() {
    const bg = {
      background:
        "url(/assets/img/checkoff-application-bg.jpg) no-repeat center center fixed",
      WebkitBackgroundSize: "cover",
      MozBackgroundSize: "cover",
      OBackgroundSize: "cover",
      backgroundSize: "cover",
      // position:'absolute',
      height: "100%",
    };

    return (
      <Container fluid>
        <Row>
          <Col xs="12" sm="8" className="mx-auto mb-5">
            {" "}
            <Row className="flex-column align-items-center">
              <h3
                className="font-circularlight"
                style={{ marginBottom: "30px" }}
              >
                {" "}
                Apply for Topup Loan
              </h3>{" "}
            </Row>{" "}
            <div className="top-list mb-4">
              {/* <div
                id="loan-details"
                className={`${
                  this.state.currentStep > 2
                    ? 'pactive'
                    : this.state.currentStep == 2 && 'active'
                } list-item`}
                style={{ borderRadius: '0 4px 4px 0' }}
              >
                Loan Details
              </div> */}
            </div>
            <Jumbotron
              className="ui-box-shadow-dark-light mb-3"
              style={{
                backgroundColor: "#fff",
              }}
            >
              {/* <Row className="justify-content-between align-items-center">
                                  <h3 className="font-circularlight brand-blue">Apply for Loan</h3>
                                  <a href="/">
                                    <img className="w-100" src="/assets/img/checkoff-credit_logo_stripped_small.png" alt="" />
                                  </a>
                                  <section className="ui-form-step">
                                    <div className="ui-timeline-box text-center">

                                      <ul className="ui-inline ui-timeline">
                                        <li className="ui-timeline-bar ui-timeline-active" />
                                        <li className="ui-timeline-bar" />
                                        <li className="ui-timeline-bar" />
                                        <li className="ui-timeline-bar" />
                                      </ul>


                                    </div>
                                  </section>
                                </Row> */}{" "}
              <section className="ui-form-step row">
                <div className="container">
                  <Form onSubmit={this.submitForm}>
                    {/* Create account */}

                    {/* Loan Details starts here */}
                    {this.state.currentStep === 2 && (
                      <Animated
                        animationIn="fadeIn"
                        animationInDuration={400}
                        isVisible
                        className="last-step "
                      >
                        <div className="ui-form-step-tab">
                          {/* <div className="mrgt1">
                            <h3 className='font-circularlight brand-blue'> Bank Details </h3>{" "}
                            <p className="brand-dark font-circular fs-16 mrgt1">
                              Fill this form for direct disbursement.
                              {' '}
                            </p>
                            {' '}
                          </div> */}
                        </div>{" "}
                        {/* <!--Step 3 ends--> */}
                        {/* <!--Step 4--> */}{" "}
                        <div className="ui-form-step-tab">
                          <div className="mrgt1">
                            {/* <h3 className='font-circularlight brand-blue'> Loan Details </h3>{" "} */}
                            <p className="brand-dark font-circular fs-16 mrgt1">
                              How much do you need and for how many Months.{" "}
                            </p>{" "}
                          </div>

                          <div className="row">
                            {/* <Col md={6}>
                              <FormField
                                id="repaymentOption"
                                formdata={this.state.formdata.repaymentOption}
                                change={element => this.updateForm(element)}
                              />
                              {' '}
                            </Col> */}{" "}
                            <Col md={6}>
                              <FormField
                                id="repaymentDays"
                                formdata={this.state.formdata.repaymentDays}
                                change={(element) => this.updateForm(element)}
                              />
                            </Col>
                            <Col md={6}>
                              <FormField
                                id="maximumloanAmt"
                                formdata={this.state.formdata.maximumloanAmt}
                                change={(element) => this.updateForm(element)}
                              />
                            </Col>
                            {/* <Col md={6}>
                              <FormField
                                id="processingFee"
                                formdata={this.state.formdata.processingFee}
                                change={element => this.updateForm(element)}
                              />
                            </Col> */}
                            <Col md={6}>
                              <FormField
                                id="loanTenor"
                                formdata={this.state.formdata.loanTenor}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </Col>{" "}
                            <div className="col-md-6">
                              <FormField
                                id="loanAmt"
                                formdata={this.state.formdata.loanAmt}
                                change={(element) => this.updateForm(element)}
                              />
                            </div>
                            <Col md={4}>
                              <FormField
                                id="amountDisbursable"
                                formdata={this.state.formdata.amountDisbursable}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </Col>
                            <div className="col-md-4">
                              <FormField
                                id="loanRepayment"
                                formdata={this.state.formdata.loanRepayment}
                                change={(element) => this.updateForm(element)}
                              />{" "}
                            </div>
                            <div className="col-md-4">
                              <FormField
                                id="dse"
                                formdata={this.state.formdata.dse}
                                change={(element) => this.updateForm(element)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="ui-form-step-tab">
                          <div className="mrgt1">
                            {/* <h3 className='font-circularlight brand-blue'>
              Terms and Conditions
            </h3>{" "} */}
                            <p className="brand-dark font-circular fs-16 mrgt1">
                              Accept the terms and condition for the loan{" "}
                            </p>{" "}
                          </div>

                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-md-6">
                                  <FormGroup check>
                                    <Label check>
                                      <Input
                                        onChange={this.termsChange}
                                        type="checkbox"
                                        checked={this.state.terms}
                                      />{" "}
                                      I accept{" "}
                                      <a
                                        style={{ color: "#F79461" }}
                                        href="https://res.cloudinary.com/frensei/image/upload/v1571043729/PAYROLL_TERMS_AND_CONDITIONS.pdf"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        terms and conditions
                                      </a>
                                    </Label>
                                  </FormGroup>
                                </div>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        {/* Step 4 ends */}
                        <Row>
                          <Col className="my-3" md={12}>
                            {" "}
                            <Alert
                              color="danger"
                              isOpen={this.state.registerError}
                              toggle={() =>
                                this.setState({ registerError: "" })
                              }
                              fade={false}
                            >
                              {this.state.registerError}
                            </Alert>
                            {/* {this.state.registerError && (
                              <Col md="6" className="mx-auto mt-1 text-center">
                                <UncontrolledAlert className="btnsecondary" color="danger">
                                  {' '}

                                  {' '}
                                </UncontrolledAlert>
                                {' '}
                              </Col>
                            )} */}{" "}
                            {this.state.loading ? (
                              <Col md="3" className="mx-auto mt-5 text-center">
                                <FontAwesome
                                  name="spinner"
                                  size="2x"
                                  style={{
                                    color: "#c82590",
                                  }}
                                  spin
                                />
                              </Col>
                            ) : (
                              <>
                                {/* <Button
                                    className="btn "
                                    //  size="md"
                                    onClick={() => {
                                      this.setState({ currentStep: 1 });
                                    }}
                                  >
                                    Back
                                  </Button> */}
                                {!this.state.submittedDone && (
                                  <>
                                    <Button
                                      type="submit"
                                      className="pull-right btnprimary"
                                      //  size="lg"
                                      color="info"
                                    >
                                      {" "}
                                      {this.state.formdata.loanAmt.value &&
                                      !this.state.formdata.loanRepayment.value
                                        ? "Proceed"
                                        : "Get Loan"}{" "}
                                    </Button>
                                  </>
                                )}
                              </>
                            )}{" "}
                          </Col>{" "}
                        </Row>
                      </Animated>
                    )}
                    {/* {this.renderStep()}
                    {this.previousButton()}
                  {this.nextButton()} */}
                  </Form>
                </div>
              </section>
              {/* <Form onSubmit={() => { ''; }}>
                                          <FormGroup>
                                          <label for="exampleEmail">Email</label>
                                          <Input />

                                        </FormGroup>
                                          <FormGroup>
                                          <label for="exampleEmail">Password</label>
                                          <Input />

                                        </FormGroup>
                                          <Button type="submit" block className="btnsecondary" color="primary" size="md">Login</Button>
                                        </Form> */}{" "}
            </Jumbotron>{" "}
          </Col>{" "}
        </Row>{" "}
      </Container>
    );
  }
}

export default NewLoann;
