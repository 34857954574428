import React from 'react';
import {
  FormGroup, Label, Input, FormFeedback,
} from 'reactstrap';

const FormFields = ({ formdata, change, id }) => {
  const showError = () => {
    let errorMessage = null;

    if (formdata.validation && !formdata.valid) {
      errorMessage = (
        <FormFeedback invalid>
          {formdata.validationMessage}
        </FormFeedback>
      );
    }

    return errorMessage;
  };

  const renderTemplate = () => {
    let formTemplate = null;

    switch (formdata.element) {
      case ('input'):
        formTemplate = (
          <FormGroup>
            <Label for="">
              {formdata.config.label}
              {
                formdata.validation.required ? '*' : ''
              }
            </Label>
            <Input
              {...formdata.config}
              value={formdata.value}
              onBlur={event => change({ event, id, blur: true })}
              onChange={event => change({ event, id, blur: false })}
              invalid={!formdata.valid}
            />
            {showError()}
          </FormGroup>
        );
        break;
      case ('select'):
        formTemplate = (
          <FormGroup>
            <Label for="">
              {formdata.config.label}
              {
                formdata.validation.required ? '*' : ''
              }
            </Label>
            <Input
              type={formdata.element}
              value={formdata.value}
              name={formdata.config.name}
              disabled={formdata.config.disabled}
              onBlur={event => change({ event, id, blur: true })}
              onChange={event => change({ event, id, blur: false })}
              invalid={!formdata.valid}
            >

              {formdata.config.options.map((item, i) => (
                <option key={i} value={item.id}>
                  {item.name}
                </option>
              ))}
            </Input>
            {showError()}
          </FormGroup>
        );
        break;
      default:
        formTemplate = null;
    }
    return formTemplate;
  };

  return (
    <div>
      {renderTemplate()}
    </div>
  );
};

export default FormFields;
