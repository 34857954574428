import React from "react";
import ReactDOM from "react-dom";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import Auth from "./components/hoc/auth";
import "./css/home.css";
import Home from "./components/Home";
import About from "./components/About";
import Contact from "./components/Contact";
import Login from "./components/Login";
import Apply from "./components/Apply";
import NewLoan from "./components/NewLoan";
import NewLoann from "./components/NewLoann";
import Topup from "./components/Topup";

import Dashboard from "./components/Dashboard";
import Profile from "./components/Profile";
import Mandate from "./components/Mandate";
import ActivateMandate from "./components/ActivateMandate";
import Checkout from "./components/Checkout";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-transitions/dist/animations.css";
import "./css/custom.css";
// false , if logged in, go to dashboard,
// true, u have to be logged in
// null, dont care if logged or not
ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route
        path="/"
        exact
        component={() => {
          window.location.href = "https://checkoff.loan/home"; // redirect to website
          return null;
        }}
      />

      <Route path="/old" exact component={Auth(Home, null, "home")} />
      <Route path="/old/about" exact component={Auth(About, null, "about")} />
      <Route
        path="/old/contact"
        exact
        component={Auth(Contact, null, "contact")}
      />

      <Route path="/login" exact component={Auth(Login, false, "login")} />
      <Route path="/apply" exact component={Auth(Apply, false, "apply")} />
      <Route path="/dashboard" exact component={Auth(Dashboard, true)} />
      <Route path="/profile" exact component={Auth(Profile, true)} />
      <Route path="/newloan" exact component={Auth(NewLoann, true)} />
      {/* <Route path="/newloann" exact component={Auth(NewLoann, true)} /> */}
      <Route path="/topup" exact component={Auth(Topup, true)} />

      <Route path="/checkout/:reference" exact component={Checkout} />

      <Route path="/mandate/:hash" exact component={Mandate} />
      <Route path="/activateMandate/:hash" exact component={ActivateMandate} />
      <Route path="*">
        <Redirect to="/apply" />
      </Route>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
