import React from 'react';
import {
  Card, CardImg, CardText, CardBody,
  CardTitle, CardSubtitle, Button, Container, Row, Col,
} from 'reactstrap';
import numeral from 'numeral';

const Mandate = (props) => {
  const data = atob(props.match.params.hash).split('&');
  const rrr = data[0];
  const name = data[1];
  const phone = data[2];
  const email = data[3];
  const bank = data[4];
  const account = data[5];
  const amount = numeral(data[6]).format('0,0.00');
  const starts = data[7];
  const ends = data[8];
  return (
    <Container>


      <Card style={{ border: 'transparent' }} className="mb-5">

        <Col md={4} className="mx-auto">
          <CardImg src="/assets/img/Remita.jpeg" alt="Card image cap" />

        </Col>
        <CardBody>
          <Row>
            <Col md={12}>
              <CardTitle className="mb-3">
                                Remita Retrieval Reference (RRR):
                {' '}
                <b>
                  {rrr}
                </b>
              </CardTitle>
              <CardSubtitle>
                {' '}
                <p>You can complete the activation of your Standing Order by:</p>
                <ul>
                  <li>
                                        Printing this form, signing and taking it to any
                      {' '}
                      {bank}
                      {' '}
                                        Branch or
                    </li>
                  <li>Logging in to your internet Bank site and Select Remita Payments, Supply You RRR and Confirm This Mandate</li>
                </ul>
                <p className="d-print-none"><strong>Your Loan will become only effective only after you have completed the activation</strong></p>

              </CardSubtitle>


            </Col>
          </Row>


          <Row className="mt-3">
            <Col md={12}>
              <CardTitle>Beneficiary Information</CardTitle>

              <p>CHECKOFF CREDIT SYSTEMS LIMITED</p>
              <p>Access Bank</p>
              <p>0739297917</p>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <CardTitle>Mandate Details</CardTitle>

              <p>
                                Name:
                {' '}
                {name}
              </p>
              <p>
                                Phone Number:
                {' '}
                {phone}
              </p>
              <p>
                                Email:
                {' '}
                {email}
              </p>
              <p>
                                Bank:
                {' '}
                {bank}
              </p>
              <p>
                                Account Number:
                {' '}
                {account}
              </p>
              <p>
                                Amount:
                {' '}
                {amount}
                {' '}
                                Naira
              </p>
              <p>
                                Starts:
                {' '}
                {starts}
              </p>
              <p>
                                Ends:
                {' '}
                {ends}
              </p>
              <p />
            </Col>
          </Row>


          <Row className="mt-3">
            <Col md={12}>
              <CardTitle className="text-left">PAYER'S SIGNATURE AND DATE</CardTitle>
              <Col className="pt-5" md={5}>
                <hr />
              </Col>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col md={12}>
              <CardTitle><b>FOR BANK USE ONLY</b></CardTitle>
            </Col>
            <Col md={6}>
              <CardTitle className="text-left">Signature Verification</CardTitle>
              <Col className="pt-5" md={11}>
                <hr />
              </Col>
            </Col>
            <Col md={6}>
              <CardTitle className="text-left">Treated by</CardTitle>
              <Col className="pt-5" md={11}>
                <hr />
              </Col>
            </Col>
          </Row>

        </CardBody>
      </Card>
    </Container>
  );
};
export default Mandate;
