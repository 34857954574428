import React from 'react';
import Script from 'react-load-script';
import { Link } from 'react-router-dom';
import axios from 'axios';
import SeeMore from 'react-show-more-text';

import shakeData from './_data/shake.json';
import teamData from './_data/team.json';
import bulbData from './_data/bulb.json';

import LottiePlayer from './layouts/partials/LottiePlayer';
import NavBar from './layouts/partials/NavBar';


const Contact = (props) => {
  const [state, setState] = React.useState({
    name: '', email: '', phoneNumber: '', sent: false,
  });
  const sendMail = (e) => {
    e.preventDefault();
    (async () => {
      try {
        await axios.post('/api/sendemployermail', { ...state });
        setState({
          ...state, sent: true,
        });
        setTimeout(() => {
          setState({
            name: '', email: '', phoneNumber: '', sent: false,
          });
        }, 5000);
      } catch (error) {
        alert('An error occured. Please try again later');
        console.log(error);
      }
    })();

    // alert(JSON.stringify(state));
  };


  return (

    <div>

      <Script
        url="/assets/js/customjs.js"
        onCreate={() => {
          '';
        }}
        onError={() => {
          '';
        }}
        onLoad={() => {
          '';
        }}
      />
      
      <NavBar />
               
      <main>
        <section className=" ui-full-bg-norm big-hero cord-landing" style={{backgroundImage: 'url("images/assets/bg@contact.jpg")'}}>
                    
                    <div className="container">
                        <div className="ui-wrapper-xlarge heading-content">
                            <div className="row">

                                <div className="col-md-7 mx-auto ui-text-center">

                                    <h1 className="headline font-gilroybold mrgt1 fs-50 animated fastest fadeIn">
                                    Contact Us
                                    </h1>
                                    <p className="font-gilroymedium fs-18 onwhite">
                                    At Checkoff Credit Systems Limited, we offer unsecured loans to workers, payroll loans to public and private sector employees. 
                                    </p>

                                    <div className="mrgt2 mrgb2">
                                      <a href="tel:+2349082600487" className="btn big-btn">Call: +234 908 260 0487</a>
                                    </div>
                        
                                </div>
                            
                            </div>

                            
                        </div>
                    </div>
                

                    </section>  
     
        <section className="ui-wrapper-xxlarge ui-full-bg-norm abt--st ui-hide" style={{backgroundImage: 'url("images/assets/bg@plain.jpg")'}}>
          <div className="container">

            <div className="row">
              
              <div className="col-md-5">
              <div className="">
                      <h2 className="font-gilroybold fs-30 mrgb1" style={{color: '#c82590'}}>Building a financial framework</h2>
                      <p className="font-gilroymedium fs-17 ui-line-height onmineshaft">
                      Our services include providing credit facilities in the public and private sector. 
                      We are hinged and committed to providing loans to middle income earners. With our expertise 
                      in banking and retail lending spanning over 40 years from our management team, we are able to deliver 
                      cutting edge services. Disbursement of our loans is delivered with speed, 
                      enabling our customers to deal with emergencies as they arise.
                      </p>
                  </div>
              </div>
            
            </div>
              
          </div>
        </section>
     
      </main>

  
      <div className="footer">

        <div className="footer__centered">

          <div className="footer__head">

            <div className="footer__aside">

              <a href="index.html" className="footer__logo"><img src="assets/img/checkoff-credit_logo_stripped_small.png" alt="checkoff" /></a>

              <nav className="footer__aside-nav">

                <div>
                  <a className="blue" href="/#">LogIn</a>
                </div>

              </nav>

              <p className="footer__aside-copyrights">
              ©
                {' '}
                {' '}
                {new Date().getFullYear()}
                {' '}
                {' '}
              Checkoff. All Rights Reserved.
              </p>

              {/* <div className="footer__socials">

              <span>
                <img src="/assets/img/Remita.jpeg" width="50" height="50" alt="checkoff" />

              </span> */}
              {/* <a
                href="https://twitter.com/getcheckoff"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/twitter-circle.svg" alt="checkoff" />

              </a>
              <a
                href="https://www.linkedin.com/company/checkoff/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/linkedin-circle.svg" alt="checkoff" />

              </a> */}

              {/* </div> */}
              <a href="https://lendlot.com" style={{ color: 'lightblue' }} id="open_ZE">
              Powered by lendlot
              </a>

            </div>
            <div className="footer__menu">

              <div className="footer__menu-col">

                <strong className="footer__menu-title">Product</strong>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">

                  <li>
                    <a href="apply">For Employees</a>
                  </li>
                  <li>
                    <a href="#emp">For Employers</a>
                  </li>

                </ul>

              </div>

              <div className="footer__menu-col">

                <strong className="footer__menu-title">Contacts</strong>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">

                  <li>
                    <a className="blue" href="mailto:customerservice@checkoff.loans">customerservice@checkoff.loans</a>
                  </li>
                  <li>
                    <a className="phone" href="tel:+2349082600487">+234 908 260 0487</a>
                  </li>

                </ul>


              </div>

            </div>

          </div>

        </div>

      </div>

    </div>
  );
};

export default Contact;
