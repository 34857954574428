import React from 'react';
import axios from 'axios';

const Checkout = () => (
        <div>
           <p>ok</p> 
        </div>
    );

export default Checkout;
