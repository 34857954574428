import React from 'react';
import {
  ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText, Container, Row, Col,
} from 'reactstrap';
import numeral from 'numeral';

const Profile = (props) => {
  const {
    firstname, lastname, address, bank, bvn, accountNumber, employer, incomeRange, monthlySalary, phoneNumber, email,
  } = props.client;
  const ms = numeral(monthlySalary).format('0,0.00');
  return (
    <Container>
      <Row>
        <Col md={12}>
          <ListGroup flush>
            <ListGroupItem>
              <ListGroupItemHeading>Name</ListGroupItemHeading>
              <ListGroupItemText>
                {`${firstname} ${lastname}`}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Email Address</ListGroupItemHeading>
              <ListGroupItemText>
                {email}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Phone Number</ListGroupItemHeading>
              <ListGroupItemText>
                {phoneNumber}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Residential Address</ListGroupItemHeading>
              <ListGroupItemText>
                {address}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Bank Details</ListGroupItemHeading>
              <ListGroupItemText>
                {`${bank} - ${bvn} - ${accountNumber}`}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Bank Verification Number</ListGroupItemHeading>
              <ListGroupItemText>
                {`${bvn}`}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Employment Details</ListGroupItemHeading>
              <ListGroupItemText>
                {`${employer}`}
              </ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem>
              <ListGroupItemHeading>Monthly Salary</ListGroupItemHeading>
              <ListGroupItemText>
                {`₦${ms}`}
              </ListGroupItemText>
            </ListGroupItem>
          </ListGroup>

        </Col>
      </Row>
    </Container>
  );
};

export default Profile;
