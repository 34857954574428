import React from "react";
import Script from "react-load-script";
import { Link } from "react-router-dom";
import axios from "axios";
import SeeMore from "react-show-more-text";

import shakeData from "./_data/shake.json";
import teamData from "./_data/team.json";
import bulbData from "./_data/bulb.json";
import headData from "./_data/head.json";

import LottiePlayer from "./layouts/partials/LottiePlayer";
import NavBar from "../components/layouts/partials/NavBar";

const About = (props) => {
  const [state, setState] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    sent: false,
  });
  const sendMail = (e) => {
    e.preventDefault();
    (async () => {
      try {
        await axios.post("/api/sendemployermail", { ...state });
        setState({
          ...state,
          sent: true,
        });
        setTimeout(() => {
          setState({
            name: "",
            email: "",
            phoneNumber: "",
            sent: false,
          });
        }, 5000);
      } catch (error) {
        alert("An error occured. Please try again later");
        console.log(error);
      }
    })();

    // alert(JSON.stringify(state));
  };

  return (
    <div>
      <Script
        url="/assets/js/customjs.js"
        onCreate={() => {
          "";
        }}
        onError={() => {
          "";
        }}
        onLoad={() => {
          "";
        }}
      />

      <NavBar />

      <main>
        <section
          className=" ui-full-bg-norm big-hero cord-landing"
          style={{ backgroundImage: 'url("images/assets/bg@hero.jpg")' }}
        >
          <div className="container">
            <div className="ui-wrapper-xlarge heading-content">
              <div className="row">
                <div className="col-md-7 mx-auto ui-text-center">
                  <h1 className="headline font-gilroybold mrgt1 fs-50 animated fastest fadeIn">
                    About Us
                  </h1>
                  <p className="font-gilroymedium fs-18 onwhite">
                    At Checkoff Credit Systems Limited, we offer unsecured loans
                    to workers, payroll loans to public and private sector
                    employees.
                  </p>

                  <div className="mrgt2 mrgb2">
                    <Link to="/apply" className="btn big-btn">
                      Apply Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="ui-wrapper-xxlarge ui-full-bg-norm abt--st"
          style={{ backgroundImage: 'url("images/assets/bg@tele.jpg")' }}
        >
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="">
                  <h2
                    className="font-gilroybold fs-30 mrgb1"
                    style={{ color: "#c82590" }}
                  >
                    Building a financial framework
                  </h2>
                  <p className="font-gilroymedium fs-17 ui-line-height onmineshaft">
                    We grant loans to public- and private-sector employees who
                    are mostly low- and mid-income earners. The expertise and
                    combined experience of our Management team spans over 40
                    years in banking and retail lending. Using technology, we
                    deliver cutting edge services, speedy disbursement and
                    hassle-free debt management, enabling our customers to deal
                    with emergencies as they arise.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="ui-wrapper-large cv--bx"
          style={{ backgroundColor: "#F3FAFE" }}
        >
          <div className="container">
            <div
              className="ui-text-center fs-35 font-gilroybold mrgb1"
              style={{ color: "#c82590" }}
            >
              Our Core Values
            </div>

            <div className="row">
              <div className="col-md-5" style={{ position: "relative" }}>
                <div className="mrgt2 ui-hide-mobile-only"></div>
                <img
                  className="ui-hide-mobile-only"
                  src="images/assets/img@cv2.jpg"
                  alt="core-v"
                  style={{ width: "400px" }}
                />
              </div>

              <div className="col-md-7 core-vs">
                <div className="row">
                  <div className="col-md-6">
                    <div className="lt--cm">
                      <LottiePlayer
                        lottieData={shakeData}
                        w={100}
                        h={100}
                        loop={true}
                        lottieClass="lt-cm"
                      />
                    </div>
                    <h3 className="font-gilroymedium fs-24 mrgb1 onmineshaft">
                      Commitment
                    </h3>
                    <p
                      className="font-gilroy fs-15 mrgb0 onmineshaft"
                      style={{ lineHeight: "1.8rem" }}
                    >
                      Commitment is our core value! We pride ourselves a team of
                      dedicated, diligent, and accountable people and
                      demonstrate these qualities in all our business dealings.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <LottiePlayer
                      lottieData={teamData}
                      w={100}
                      h={100}
                      loop={true}
                      lottieClass="lt-team"
                    />
                    <h3 className="font-gilroymedium fs-24 mrgb1 onmineshaft">
                      Teamwork
                    </h3>
                    <p
                      className="font-gilroy fs-15 mrgb0 onmineshaft"
                      style={{ lineHeight: "1.8rem" }}
                    >
                      We have built a strong and productive team. Our team is
                      founded on deep levels of collaboration, communication and
                      togetherness attributable only to the Zulu word – Ubuntu.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="lt-bulb">
                      <LottiePlayer
                        lottieData={bulbData}
                        w={120}
                        h={120}
                        loop={true}
                        lottieClass="lt-bulb"
                      />
                    </div>
                    <h3 className="font-gilroymedium fs-24 mrgb1 onmineshaft">
                      Innovation
                    </h3>
                    <p
                      className="font-gilroy fs-15 mrgb0 onmineshaft"
                      style={{ lineHeight: "1.8rem" }}
                    >
                      For us, Innovation is not a fad but rather our way of
                      life. We are young and dynamic enough to take (calculated)
                      risk, tapping into new opportunities of an ever-changing
                      market.
                    </p>
                  </div>
                  <div className="col-md-6">
                    <div className="mrgt2">
                      <LottiePlayer
                        lottieData={headData}
                        w={60}
                        h={60}
                        loop={true}
                        lottieClass="lt-people"
                      />
                    </div>
                    <h3 className="font-gilroymedium fs-24 mrgb1 mt-4 onmineshaft">
                      People First
                    </h3>
                    <p
                      className="font-gilroy fs-15 mrgb0 onmineshaft"
                      style={{ lineHeight: "1.8rem" }}
                    >
                      We take care of our people and they take care of our
                      customers. We see everyone who deals with Checkoff is a
                      stakeholder and they all deserve our utmost respect.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="abt-offer">
          <div className="abtf-img">
            <img
              className="ui-show-mobile-only"
              src="images/assets/img@telesales.jpg"
              alt="im-bb"
            />
            <img
              className="ui-hide-mobile-only"
              src="images/assets/bg@offering.jpg"
              alt="im-bb"
            />
          </div>

          <div
            className="ui-full-bg-norm"
            style={{ backgroundImage: 'url("images/assets/bg@plain.jpg")' }}
          >
            <div className="container">
              <div className="off--up">
                <h2 className="font-gilroybold fs-35 onwhite mrgb1 ui-text-center">
                  Our Business Offerings
                </h2>
                <div className="row">
                  <div className="col-md-4">
                    <div className="bg-white of--itm ui-box-shadow-dark-light">
                      <img
                        src="images/assets/icon@public.svg"
                        alt="icon"
                        width="60px"
                      />
                      <h3
                        className="font-gilroymedium fs-20 mrgb1 mrgt1"
                        style={{ color: "#b62b7e" }}
                      >
                        Public Sector
                      </h3>
                      <p
                        className="font-gilroy fs-15 mrgb0 onmineshaft"
                        style={{ lineHeight: "1.8rem" }}
                      >
                        Payroll loans to Federal and State Government workers at
                        discounted price and convenient repayment periods.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="bg-white of--itm ui-box-shadow-dark-light">
                      <img
                        src="images/assets/icon@private.svg"
                        alt="icon"
                        width="60px"
                      />
                      <h3
                        className="font-gilroymedium fs-20 mrgb1 mrgt1"
                        style={{ color: "#b62b7e" }}
                      >
                        Private Sector
                      </h3>
                      <p
                        className="font-gilroy fs-15 mrgb0 onmineshaft"
                        style={{ lineHeight: "1.8rem" }}
                      >
                        Loans to private-sector workers employed by any of our
                        partners. Our preferred repayment method is payroll
                        deductions.
                      </p>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="bg-white of--itm ui-box-shadow-dark-light">
                      <img
                        src="images/assets/icon@lending.svg"
                        alt="icon"
                        width="60px"
                      />
                      <h3
                        className="font-gilroymedium fs-20 mrgb1 mrgt1"
                        style={{ color: "#b62b7e" }}
                      >
                        Emergency Loans
                      </h3>
                      <p
                        className="font-gilroy fs-15 mrgb0 onmineshaft"
                        style={{ lineHeight: "1.8rem" }}
                      >
                        Borrow against your next salary at the click of your
                        phone, made possible by our payment partners.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mrgt3 ui-group-button ui-text-center">
                  <Link to="/apply" type="submit" className="btn big-btn">
                    Apply Now
                  </Link>
                  <a
                    href="mailto:customerservice@checkoff.loans"
                    className="btn big-btn black"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section
          className="ui-wrapper-large ui-full-bg-norm"
          style={{
            backgroundColor: "#1d161a",
            backgroundImage: 'url("images/assets/bg@black.jpg")',
          }}
        >
          <div className="container">
            <div
              className="ui-text-center fs-35 font-gilroybold mrgb2"
              style={{ color: "#fff" }}
            >
              Management Team
            </div>

            <div
              className="row boxes"
              style={{ justifyContent: "space-around" }}
            >
              <div className="col-md-4">
                <div className="pdl1 pdr1 team">
                  <img src="images/assets/img@habila.jpg" alt="Habila M-L" />

                  <div className="bg-white ui-box-shadow-dark-light pd--bx">
                    <p
                      className="font-gilroybold fs-20 mb-1"
                      style={{ color: "#c82590" }}
                    >
                      Habila Musa-Luka
                    </p>
                    <span className="fs-13 text-muted font-gilroymedium">
                      MD, Co-Founder
                    </span>
                    <div className="mrgb1"></div>

                    <SeeMore
                      /* Default options */
                      lines={3}
                      more="see more"
                      less="see less"
                      className="font-gilroy brandcox-firefly s--more"
                      anchorClass="s--lnk"
                      expanded={false}
                    >
                      <span className="fs-14">
                        15 years retail finance experience; 118 Money UK,
                        Barclaycard UK, OneFi, Carbon. Graduate of Ahmadu Bello
                        University (BSc) and University of South Wales (MSc).
                      </span>
                    </SeeMore>

                    {/* <Link to="" className="c-link font-gilroyfs-16 brand-bluelight sv--link">Our Services <span className="fe fe-chevrons-right"></span></Link> */}
                    <div className="ui-hide-mobile-only ui-separate-small"></div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="pdl1 pdr1 team">
                  <img src="images/assets/img@uduks.jpg" alt="Habila M-L" />

                  <div className="bg-white ui-box-shadow-dark-light pd--bx">
                    <p
                      className="font-gilroybold fs-20 mb-1"
                      style={{ color: "#c82590" }}
                    >
                      Udukheli Izebuno
                    </p>
                    <span className="fs-13 text-muted font-gilroymedium">
                      Executive Director
                    </span>
                    <div className="mrgb1"></div>

                    <SeeMore
                      /* Default options */
                      lines={3}
                      more="see more"
                      less="see less"
                      className="font-gilroy brandcox-firefly s--more"
                      anchorClass="s--lnk"
                      expanded={false}
                    >
                      <span className="fs-14">
                        15 years banking, business development and technology
                        experience; Globacom, Diamond Bank, OneFi, Computer
                        Warehouse Group. Ambrose Ali University (Bsc) and
                        Business Schools Netherland (MBA).
                      </span>
                    </SeeMore>

                    {/* <Link to="" className="c-link font-gilroyfs-16 brand-bluelight sv--link">Our Services <span className="fe fe-chevrons-right"></span></Link> */}
                    <div className="ui-hide-mobile-only ui-separate-small"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <div className="footer">
        <div className="footer__centered">
          <div className="footer__head">
            <div className="footer__aside">
              <a href="index.html" className="footer__logo">
                <img
                  src="assets/img/checkoff-credit_logo_stripped_small.png"
                  alt="checkoff"
                />
              </a>

              <nav className="footer__aside-nav">
                <div>
                  <a className="blue" href="/#">
                    LogIn
                  </a>
                </div>
              </nav>

              <p className="footer__aside-copyrights">
                © {new Date().getFullYear()} Checkoff. All Rights Reserved.
              </p>

              {/* <div className="footer__socials">

              <span>
                <img src="/assets/img/Remita.jpeg" width="50" height="50" alt="checkoff" />

              </span> */}
              {/* <a
                href="https://twitter.com/getcheckoff"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/twitter-circle.svg" alt="checkoff" />

              </a>
              <a
                href="https://www.linkedin.com/company/checkoff/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/linkedin-circle.svg" alt="checkoff" />

              </a> */}

              {/* </div> */}
              <a
                href="https://lendlot.com"
                style={{ color: "lightblue" }}
                id="open_ZE"
              >
                Powered by lendlot
              </a>
            </div>
            <div className="footer__menu">
              <div className="footer__menu-col">
                <strong className="footer__menu-title">Product</strong>

                <span className="ui-hide">No show</span>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">
                  <li>
                    <a href="apply">For Employees</a>
                  </li>
                  <li>
                    <a href="#emp">For Employers</a>
                  </li>
                </ul>
              </div>

              <div className="footer__menu-col">
                <strong className="footer__menu-title">Contacts</strong>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">
                  <li>
                    <a
                      className="blue"
                      href="mailto:customerservice@checkoff.loans"
                    >
                      customerservice@checkoff.loans
                    </a>
                  </li>
                  <li>
                    <a className="phone" href="tel:+2349082600487">
                      +234 908 260 0487
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
