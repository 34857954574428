import React from "react";
import Script from "react-load-script";
import { Link } from "react-router-dom";
import axios from "axios";

import NavBar from "./layouts/partials/NavBar";

const Home = (props) => {
  const [state, setState] = React.useState({
    name: "",
    email: "",
    phoneNumber: "",
    sent: false,
  });
  const sendMail = (e) => {
    e.preventDefault();
    (async () => {
      try {
        await axios.post("/api/sendemployermail", { ...state });
        setState({
          ...state,
          sent: true,
        });
        setTimeout(() => {
          setState({
            name: "",
            email: "",
            phoneNumber: "",
            sent: false,
          });
        }, 5000);
      } catch (error) {
        alert("An error occured. Please try again later");
        console.log(error);
      }
    })();

    // alert(JSON.stringify(state));
  };

  return (
    <div>
      <Script
        url="/assets/js/customjs.js"
        onCreate={() => {
          "";
        }}
        onError={() => {
          "";
        }}
        onLoad={() => {
          "";
        }}
      />

      <NavBar />

      <section
        className=" ui-full-bg-norm big-hero cord-landing"
        style={{ backgroundImage: 'url("images/assets/bg@home.jpg")' }}
      >
        <div className="container">
          <div className="ui-wrapper-xlarge heading-content">
            <div className="row">
              <div className="col-md-7">
                <h1 className="headline font-gilroybold mrgt1 fs-45 animated fastest fadeIn">
                  Low cost loans for employees
                </h1>
                <p className="font-gilroymedium fs-18 onwhite">
                  Checkoff offers employees a more convenient loan option, no
                  physical documents, no bank statements and instant credit to
                  your bank account.
                </p>

                <div className="mrgt2 mrgb2 ui-group-button">
                  <Link to="/apply" className="btn big-btn">
                    I am an Employee
                  </Link>
                  <a href="#emp" className="btn big-btn black">
                    I am an Employer
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="base-content">
        <div className="base-content__aligner">
          <ul
            style={{ paddingLeft: 0 }}
            className="emplyee-features emplyee-features_small"
          >
            <li className="emplyee-features__item">
              <strong className="employee-features__big-text">4%</strong>
              <strong>4% Starting Rate</strong> per month
            </li>
            <li className="emplyee-features__item loan-amount">
              <strong className="employee-features__big-text">N1m</strong>
              <strong>Up to N1,000,000</strong> loan amount
            </li>
            <li className="emplyee-features__item calendar">
              <strong className="employee-features__big-text">12</strong>
              <strong>Up to 12 months</strong> loan term
            </li>
            <li className="emplyee-features__item minutes">
              <strong className="employee-features__big-text">~24</strong>
              <strong>Same Day</strong> Disbursement
            </li>
          </ul>
        </div>
      </section>

      <section className="base-content base-content__how-it-works">
        <div className="base-content__aligner">
          <h2 className="base-content__heading">How Does It Work</h2>

          <div className="how-does-it-work-wrap">
            <div className="employee-tabs">
              <div className="employee-tabs__item active">
                <div className="employee-tabs__item_num">1</div>
              </div>

              <div className="employee-tabs__item">
                <div className="employee-tabs__item_num">2</div>
              </div>

              <div className="employee-tabs__item">
                <div className="employee-tabs__item_num">3</div>
              </div>

              <div className="employee-tabs__item">
                <div className="employee-tabs__item_num">4</div>
              </div>
            </div>
            <div className="how-does-it-work-align">
              <div className="how-does-it-work">
                <div className="tab-content active">
                  <strong className="how-does-it-work__title">
                    Apply Online
                  </strong>
                  <p className="how-does-it-work__text">
                    Select your desired loan amount and review the repayment
                    schedule and costs. If you are happy with your loan terms,
                    submit electronically and we'll do the rest for you.
                  </p>
                </div>
                <div className="tab-content ">
                  <strong className="how-does-it-work__title">
                    Register &amp; Verify Employment
                  </strong>
                  <p className="how-does-it-work__text">
                    We will verify your employment. Employment verification is
                    automated and it takes one click and only a few minutes for
                    your loan and monthly repayment to be approved by your
                    employer.
                  </p>
                </div>
                <div className="tab-content">
                  <strong className="how-does-it-work__title">
                    Repayments
                  </strong>
                  <p className="how-does-it-work__text">
                    Typically, repayments are set up automatically through your
                    payroll by your employer. But with some employers, employees
                    are asked to set up repayments on their own through card
                    payment, direct debit or post dated cheques.
                  </p>
                </div>

                <div className="tab-content">
                  <strong className="how-does-it-work__title">
                    {" "}
                    &quot;Instant&quot; Cash
                  </strong>
                  <p className="how-does-it-work__text">
                    Your funds are disbursed electronically into your salary
                    account same day.
                  </p>
                </div>
              </div>
            </div>

            <div
              className="img"
              style={{
                left: 146,
                width: 378,
              }}
            >
              <img src="assets/img/bulb.png" alt="" />
            </div>
          </div>
        </div>
      </section>

      <section className="base-content base-content__form" id="emp">
        <div className="base-content__aligner">
          <h2 className="base-content__heading">I am an Employer</h2>
          <p className="base-content__paragraph">
            If your organization will like to partner with us, kindly fill your
            details below.
          </p>

          <form
            id="refer_employer"
            onSubmit={sendMail}
            className="refer_employer"
            style={{
              maxWidth: 1090,
            }}
          >
            <div className="refer_employer__i refer_employer__i-name">
              <input
                required
                onChange={({ target: { value: name } }) =>
                  setState({ ...state, name })
                }
                type="text"
                value={state.name}
                name="name"
                placeholder="Name"
              />
            </div>
            <div className="refer_employer__i refer_employer__i-name">
              <input
                required
                onChange={({ target: { value: phoneNumber } }) =>
                  setState({ ...state, phoneNumber })
                }
                type="number"
                value={state.phoneNumber}
                name="name"
                placeholder="Phone Number"
              />
            </div>
            <div className="refer_employer__i refer_employer__i-mail">
              <input
                required
                onChange={({ target: { value: email } }) =>
                  setState({ ...state, email })
                }
                type="email"
                value={state.email}
                name="email"
                placeholder="Email"
              />
            </div>

            <div className="refer_employer__s ml0">
              <button type="submit" className="apply-button wide">
                Send
              </button>
              {/* <input type="submit"  value="" /> */}
            </div>

            {state.sent && (
              <div className="">
                <span
                  style={{
                    maxWidth: 1090,
                  }}
                  className="refer_employer__success_text"
                >
                  Thanks! We’ll reach out to your HR as soon as we can.
                </span>
                {/* <button className="apply-button" id="form_subscribe-done" type="button">Ok</button> */}
              </div>
            )}
          </form>
        </div>
      </section>

      <div className="footer">
        <div className="footer__centered">
          <div className="footer__head">
            <div className="footer__aside">
              <a href="index.html" className="footer__logo">
                <img
                  src="assets/img/checkoff-credit_logo_stripped_small.png"
                  alt="checkoff"
                />
              </a>

              <nav className="footer__aside-nav">
                <div>
                  <a className="blue" href="/#">
                    LogIn
                  </a>
                </div>
              </nav>

              <p className="footer__aside-copyrights">
                © {new Date().getFullYear()} Checkoff. All Rights Reserved.
              </p>

              {/* <div className="footer__socials">

              <span>
                <img src="/assets/img/Remita.jpeg" width="50" height="50" alt="checkoff" />

              </span> */}
              {/* <a
                href="https://twitter.com/getcheckoff"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/twitter-circle.svg" alt="checkoff" />

              </a>
              <a
                href="https://www.linkedin.com/company/checkoff/"
                rel="noopener noreferrer"
                target="_blank"
              >
                <img src="assets/img/linkedin-circle.svg" alt="checkoff" />

              </a> */}

              {/* </div> */}
              <a
                href="https://nipek.github.io"
                style={{ color: "lightblue" }}
                id="open_ZE"
              >
                Powered by Nipek
              </a>
            </div>
            <div className="footer__menu">
              <div className="footer__menu-col">
                <strong className="footer__menu-title">Product</strong>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">
                  <li>
                    <a href="apply">For Employees</a>
                  </li>
                  <li>
                    <a href="#emp">For Employers</a>
                  </li>
                </ul>
              </div>

              <div className="footer__menu-col">
                <strong className="footer__menu-title">Contacts</strong>

                <ul style={{ paddingLeft: 0 }} className="footer__menu-list">
                  <li>
                    <a
                      className="blue"
                      href="mailto:customerservice@checkoff.loans"
                    >
                      customerservice@checkoff.loans
                    </a>
                  </li>
                  <li>
                    <a className="phone" href="tel:+2349082600487">
                      +234 908 260 0487
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
