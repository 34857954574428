import React, { Component } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Table, Row, Col,
} from 'reactstrap';
import moment from 'moment';
import numeral from 'numeral';
import LoanRepayment from './LoanRepayment';

class Loan extends Component {
  state = {
    repaymentSchedulePeriod: [],
  }

  componentDidMount() {
    // this.setState({
    //   repaymentSchedulePeriod: this.props.repaymentSchedulePeriod,
    // });
  }


  renderStatus = (status) => {
    let statusButton = null;

    let className = 'info';
    let statusType = '';
    if (status === 1) {
      className = 'info';
      statusType = 'Unapproved';
    } else if (status === 5) {
      className = 'success';
      statusType = 'Approved';
    } else if (status === 3) {
      className = 'secondary';
      statusType = 'Modification Requested';
    } else if (status === 7) {
      className = 'primary';
      statusType = 'Disbursed';
    } else if (status === 9) {
      className = 'danger';
      statusType = 'Rejected';
    }

    statusButton = (
      <Button size="sm" color={className}>{statusType}</Button>
    );
    return statusButton;
  };

  //  renderStatus = (id, outstanding) => {
  //    let statusButton = null;

  //    let className = 'info';
  //    let statusType = '';
  //    let out = 0;
  //    if (outstanding > 0) {
  //      out = numeral(outstanding).format('0,0.00');
  //      statusType = `Outstanding Payment of ₦${out}`;
  //      className = 'danger';
  //    } else {
  //      out = numeral(outstanding).format('0,0.00');
  //      statusType = `Outstanding Payment of ₦${out}`;
  //      className = 'success';
  //    }
  //    statusButton = (
  //      <Button size="sm" onClick={() => { this.props.toggle(id); }} color={className}>{statusType}</Button>
  //    );
  //    return statusButton;
  //  };

  render() {
    return (
      <tr>
        <th scope="row">{this.props.no}</th>
        <td>
          {' '}
        ₦
          {numeral(this.props.loanAmount).format('0,0.00')}
        </td>
        <td>
      ₦
          {numeral(this.props.repayment).format('0,0.00')}
        </td>
        <td>
          {this.props.loanTenor}
        </td>
        <td>{moment(this.props.createdAt).format('DD MMMM YYYY')}</td>
        <td>{moment(this.props.createdAt).add(this.props.loanTenor, 'M').format('DD MMMM YYYY')}</td>
        <td>
          {this.renderStatus(this.props.status)}

        </td>
        <td>
          <Button disabled={this.props.closed || this.props.status == 1} onClick={() => this.props.history.push('/topup')} size="sm" color="secondary">Topup</Button>

        </td>
        {/* <td>
           {this.renderStatus(this.props.id, this.props.repaymentSchedule.totalOutstanding)}

         </td> */}
        {/* <Modal size="lg" centered isOpen={this.props.modal === this.props.id} toggle={() => { this.props.toggle(null); }}>
           <ModalHeader toggle={() => { this.props.toggle(null); }}>
             {' '}
₦
             {numeral(this.props.repaymentSchedule.totalPrincipalExpected).format('0,0.00')}
          + ₦
             {numeral(this.props.repaymentSchedule.totalInterestCharged).format('0,0.00')}
             {' '}
for
             {' '}
             {this.props.numberOfRepayments}
             {' '}
months

           </ModalHeader>
           <ModalBody>
             <Row>
               <Col md={12} className="my-4">
                 <Table dark striped hover responsive>
                   <thead>
                    <tr>
                      <th>#</th>
                      <th>Date</th>
                      <th>Amount Due</th>
                      <th>Interest Due</th>
                      <th>Total to be Paid</th>
                      {
        this.props.repaymentOption == 'card' ? <th /> : null
      }
                    </tr>
                  </thead>
                   <tbody>
                    {this.state.repaymentSchedulePeriod.map((itemm, i) => (
                      <LoanRepayment loanid={this.props.id} client={this.props.client} toggle={this.props.toggle} {...itemm} {...this.props} no={i + 1} key={`${itemm.totalDueForPeriod + itemm.period + i}`} />
                    ))}
                  </tbody>
                 </Table>
               </Col>
             </Row>

           </ModalBody>
           <ModalFooter>

             <Button color="danger" className="w-100" onClick={() => { this.props.toggle(null); }}>Close</Button>
           </ModalFooter>
         </Modal> */}
      </tr>
    );
  }
}

export default Loan;
