import React, { Component } from 'react';
import {
  Container, Row, Col, Jumbotron, Button, Form, ButtonGroup, FormGroup, Label, Input, UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';
import numeral from 'numeral';
import FontAwesome from 'react-fontawesome';
import FormField from './widgets/FormFields';
import { getRepayment, getmaxloan, getnewrepayment } from './widgets/loanCalculator';

class NewLoan extends Component {
  state = {
    terms: false,
    outstandingloan: '',
    err: '',
    loading: false,
    max: '',
    interest: '',
    loanAmount: '',
    repaymen: '',
    loanTenor: '',
    email: this.props.client.email,
    clientId: this.props.client.clientId,
    salary: this.props.client.monthlySalary,
    dti: '',
    repaymentOption: '',
    accountNumber: this.props.client.accountNumber,
    recruiter: this.props.client.recruiter,
    client: this.props.client,
    options: [
      {
        name: '',
        id: '',
      },
    ],
    ban: {
      element: 'select',
      value: `${this.props.client.bank},${this.props.client.bankId}`,
      config: {
        name: 'bank_input',
        type: 'text',
        label: 'Bank',
        options: [],
      },
      validation: {
        required: true,
      },
      valid: true,
      touched: false,
      validationMessage: '',
    },
    isEligible: true,
  }

  termsChange = () => {
    this.setState(prevState => ({
      terms: !prevState.terms,
    }));
  }

  onLoadData = async () => {
    const bankOptions = [
      {
        name: '',
        id: '',
      },
    ];
    const statecopy = { ...this.state };

    const Tenoroptions = [
      {
        name: '',
        id: '',
      },
    ];

    const recruiterData = this.state.recruiter.split(',');
    try {
      statecopy.interest = recruiterData[2];
      statecopy.dti = recruiterData[1];
      for (let i = 3; i <= parseInt(recruiterData[5], 10); i += 3) {
        if (i == 1) {
          Tenoroptions.push({
            name: `${i} Month`,
            id: i,
          });
        } else {
          Tenoroptions.push({
            name: `${i} Months`,
            id: i,
          });
        }
        if (i >= parseInt(recruiterData[5], 10)) {
          statecopy.options = Tenoroptions;
          break;
        }
      }


      // 33 for bank
      let resp = await axios.get('/api/getCode/33');
      resp.data.map(bank => (
        bankOptions.push({
          name: bank.name,
          id: `${bank.name},${bank.id}`,
        })
      ));
      statecopy.ban.config.options = bankOptions;


      resp = await axios.get(`/api/getLoans/${this.state.clientId}`);
      const out = (resp.data[0].principalExpected - resp.data[0].principalPaid) > 10 ? resp.data[0].principalExpected - resp.data[0].principalPaid : null;
      statecopy.err = out && `You owe ${out}`;
      statecopy.outstandingloan = out;
      statecopy.repaymentOption = resp.data[0].lastestRepaymentOption;
      if (recruiterData[0] == 'Not Applicable') {
        const { data } = await axios.post('/api/getCustomerRemita', { phoneNumber: this.props.client.phoneNumber });
        if (!data.isEligible) {
          statecopy.isEligible = data.isEligible;
          statecopy.err = 'Sorry you are not eligible for a loan right now.';
        }
        statecopy.max = parseInt(recruiterData[4], 10) < parseInt(data.amount, 10) ? parseInt(recruiterData[4], 10) : parseInt(data.amount, 10);
      }

      // the splice the only reutrn the needed data from mifos
    } catch (err) {
      console.log(err);
    } finally {
      this.setState({ ...statecopy });
    }
  }

  componentDidMount = async () => {
    this.onLoadData();
  }

  updateForm = (e, id) => {
    let repay = this.state.repaymen; let maxloan = this.state.max; const statecopy = { ...this.state }; const newElement = {}; const
      Tenoroptions = [];
    if ('event' in e) {
      newElement = {
        ...this.state[e.id],
      };
    }
    const recruiterData = this.state.recruiter.split(',');
    if (id === 'loanAmount' && this.state.max) {
      //   console.log(parseInt(recruiterData[4], 10));
      if (e.target.value > parseInt(numeral(`${this.state.max}`).value(), 10) || e.target.value > parseInt(recruiterData[4], 10)) {
        const maxreason = parseInt(recruiterData[4], 10) < parseInt(numeral(`${this.state.max}`).value(), 10) ? numeral(recruiterData[4]).format('0,0.00') : this.state.max;
        //  console.log(maxreason);
        return this.setState({
          err: `You can only apply for the maximum of ${maxreason} for ${this.state.loanTenor} MONTHS`,
        });
      }
      if (recruiterData[0].toLowerCase() == 'not applicable') {
        Tenoroptions = [
          {
            name: '',
            id: '',
          },
        ];

        if (e.target.value <= 50000) {
          statecopy.options = [{
            name: '1 Month',
            id: 1,
          }];
          statecopy.loanTenor = 1;
        } else {
          for (let i = 3; i <= parseInt(recruiterData[5], 10); i += 3) {
            if (i == 1) {
              Tenoroptions.push({
                name: `${i} Month`,
                id: i,
              });
            } else {
              Tenoroptions.push({
                name: `${i} Months`,
                id: i,
              });
            }
            if (i >= parseInt(recruiterData[5], 10)) {
              statecopy.options = Tenoroptions;
              statecopy.loanTenor = '';
              break;
            }
          }
        }
      }

      repay = numeral(getnewrepayment(e.target.value, recruiterData[2], this.state.loanTenor)).format('0,0.00');
      // console.log(repay);
    }
    if (id === 'loanTenor' || id === 'salary') {
      let tenor = this.state.loanTenor;
      let salary = this.state.salary;
      if (id === 'loanTenor') {
        tenor = e.target.value;
      } else if (id === 'salary') {
        salary = e.target.value;
      }
      if (recruiterData[0].toLowerCase() != 'not applicable') {
        maxloan = getmaxloan(getRepayment(salary, recruiterData[1], 0), tenor, recruiterData[2]);

        maxloan = numeral(Math.round(maxloan / 10000) * 10000).format('0,0.00');
      }
      if (this.state.loanAmount) {
        if (this.state.loanAmount > parseInt(numeral(`${this.state.max}`).value(), 10)) {
          return this.setState({
            err: `You can only apply for the maximum of ${this.state.max} for ${e.target.value} MONTHS`,
          });
        }
        repay = numeral(getnewrepayment(this.state.loanAmount, recruiterData[2], tenor)).format('0,0.00');
      }
    }
    if ('event' in e) {
      newElement.value = e.event.target.value;

      statecopy[e.id] = newElement;
    } else {
      statecopy[id] = e.target.value;
    }


    statecopy.repaymen = repay;
    statecopy.max = maxloan;
    this.setState({ ...statecopy });
  }

  submitForm = (event) => {
    event.preventDefault();
    // if (this.state.outstandingloan > 0) {
    //   return this.setState({
    //     err: `This loan will not be Submitted. You owe ${this.state.outstandingloan}`,
    //   });
    // }
    if (!this.state.isEligible) {
      return this.setState({
        err: 'You are currently not eligible for a loan now',
      });
    }
    if (!this.state.terms) {
      return this.setState({
        err: 'Please accept term and conditions',
      });
    }
    if (this.state.loanAmount && this.state.loanTenor) {
      this.setState({ loading: true });
      const load = {
        ...this.state,
        repayment: parseInt(numeral(`${this.state.repaymen}`).value(), 10),
        type: 'dontcreateonmifos',
        monthlySalary: this.state.salary,
        bank: this.state.ban.value.split(',')[0],
        bankId: this.state.ban.value.split(',')[1],
      };
      axios.post('/api/createLoan', load).then(({ data }) => {
        //  console.log(data);
        if (data.err != 0) {
          this.setState({
            err: data.err,
          });
          return;
        }

        if (data && this.state.repaymentOption == 'direct' && data.mandate && data.mandate.startDate) {
          // if (data.online) {
          //   axios.post('/api/requestOtp', { mandateId: data.mandate.mandateId, requestId: data.mandate.requestId }).then(({ data }) => {
          //     this.props.history.push(`/activateMandate/${btoa(`${JSON.stringify(data)}`)}`);
          //   }).catch(({ response }) => {
          //     console.log(response);
          //   });
          // } else {
          setTimeout(() => {
            this.props.history.push(`/mandate/${btoa(`${data.mandate.mandateId}&${data.payerName}&${data.payerPhone}&${data.payerEmail}&${data.bank}&${data.account}&${data.amount}&${data.mandate.startDate}&${data.mandate.endDate}`)}`);
          }, 4500);
          // }
        } else {
          this.setState({
            err: 'Loan Submitted Successfully for Processing. Changes will Reflect on your Dashboard within 24 Hours',
          });
        }
      }).catch((err) => {
        this.setState({
          err: 'An Error Occured',
        });
        console.error(err.response);
      });
    }
  }

  render() {
    return (
      <Container>
        <Row>
          <Col md={6} className="mx-auto">
            <h3 className="text-center">Apply for a New Loan</h3>
            <Form className="my-5" onSubmit={this.submitForm}>
              <FormGroup>
                <Label for="">
                  Monthly Salary
                </Label>
                <Input
                  type="text"
                  value={this.state.salary}
                  onChange={(e) => { this.updateForm(e, 'salary'); }}
                />
              </FormGroup>
              <FormField
                id="ban"
                formdata={this.state.ban}
                change={element => this.updateForm(element)}
              />
              <FormGroup>
                <Label for="">
                  Salary Account Number
                </Label>
                <Input
                  type="text"
                  value={this.state.accountNumber}
                  onChange={(e) => { this.updateForm(e, 'accountNumber'); }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="">
                  Loan Tenure
                </Label>
                <Input
                  type="select"
                  value={this.state.loanTenor}
                  onChange={(e) => { this.updateForm(e, 'loanTenor'); }}
                >

                  {this.state.options.map((item, i) => (
                    <option key={i} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="">
                  Repayment Options
                </Label>
                <Input
                  type="select"
                  value={this.state.repaymentOption}
                  onChange={(e) => { this.updateForm(e, 'repaymentOption'); }}
                >
                  <option value="">
                    -- Select repayment option --
                  </option>
                  <option value="direct">
                    Direct
                  </option>
                  <option value="card">
                    Card
                  </option>
                  <option value="payroll">
                    Payroll
                  </option>
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="">
                  Maximum Loan
                </Label>
                <Input
                  type="text"
                  disabled
                  value={this.state.max}
                />
              </FormGroup>


              <FormGroup>
                <Label for="">
                  Loan Amount
                  {' '}

                </Label>
                <Input
                  type="number"
                  value={this.state.loanAmount}
                  onChange={(e) => { this.updateForm(e, 'loanAmount'); }}
                />
              </FormGroup>
              <FormGroup>
                <Label for="">
                  Repayment
                </Label>
                <Input
                  type="text"
                  disabled
                  value={this.state.repaymen}
                />
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input onChange={this.termsChange} type="checkbox" checked={this.state.terms} />
                  {' '}
                  I accept
                  {' '}
                  <a style={{ color: '#F79461' }} href="https://res.cloudinary.com/frensei/image/upload/v1571043729/PAYROLL_TERMS_AND_CONDITIONS.pdf" target="_blank" rel="noopener noreferrer">terms and conditions</a>
                </Label>
              </FormGroup>
              <Row>
                <Col md={12}>
                  {
                    this.state.err
                      ? (
                        <Col md="12" className="mx-auto mt-1 text-center">
                          <UncontrolledAlert className="btnsecondary" color="danger">
                            {this.state.err}
                          </UncontrolledAlert>
                        </Col>
                      ) : null
                  }
                  <Col md="3" className="mx-auto mt-5 text-center">
                    <Button type="submit" disabled={this.state.loading} className="btnprimary w-100" color="info">Submit</Button>

                  </Col>

                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NewLoan;
